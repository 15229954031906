/* eslint-disable react/no-unknown-property */
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import styled from 'styled-components'

import { ButtonLink, ContainerCard, TitleLarge } from '../common/styles'
import MarkdownWrapper from '../components/MarkdownWrapper'
import border from '../constants/border'
import breakpoint from '../constants/breakpoint'
import spacing from '../constants/spacing'
import Layout from '../layouts'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Illustration = styled(GatsbyImage)<{
  width: number
  height: number
}>`
  width: ${({ width }) => width / 1.5}px;
  height: ${({ height }) => height / 1.5}px;
  max-width: 100%;
  border-radius: ${border.LARGE_RADIUS};
  object-fit: cover;
  object-position: center center;
  margin: ${spacing.MAIN_PADDING} 0;

  @media ${breakpoint.TABLET} {
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
  }
`

const BackButton = styled(ButtonLink)`
  display: inline-flex;
  margin-top: ${spacing.MEDIUM_PADDING};
  margin-bottom: ${spacing.MEDIUM_PADDING};
  margin-left: ${spacing.MEDIUM_PADDING};
`

const BackButtonText = styled.span`
  display: flex;
  align-items: center;
`

const QuoteButton = styled(ButtonLink)`
  margin-top: 42px;
`

const Nav = styled.nav`
  display: flex;
  justify-content: start;
  width: 100%;
  max-width: 1000px;
`

const Card = styled(ContainerCard)`
  margin: 0px 35px 35px;
  width: 100%;
`

const UpdatedAt = styled.p`
  font-size: 12px;
  margin-bottom: 28px;
  font-style: italic;
  text-align: left;
  width: 100%;
`

const BackArrow = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z'
      fill='currentColor'
    />
  </svg>
)

const Article = ({
  data: { strapiArticle }
}: PageProps<Queries.ArticleQuery>) => {
  const imageHeight =
    strapiArticle?.image?.localFile?.childImageSharp?.gatsbyImageData.height!
  const imageWidth =
    strapiArticle?.image?.localFile?.childImageSharp?.gatsbyImageData.width!

  const imageRatio = imageHeight / imageWidth

  const illustrationHeight = imageRatio * 500
  const illustrationWidth = 500
  const imageData = strapiArticle?.image
    ?.localFile as unknown as IGatsbyImageData
  const illustrationImage = getImage(imageData)

  return (
    <Layout
      smallNavBar
      transparentBg={false}
      seo={{
        title: strapiArticle?.seo?.title,
        description: strapiArticle?.seo?.description
      }}
    >
      <Container>
        <Nav>
          <BackButton to='/blog/' outline>
            <BackArrow />
            <BackButtonText>Retour aux articles</BackButtonText>
          </BackButton>
        </Nav>
        <Card>
          <TitleLarge>{strapiArticle?.title}</TitleLarge>
          <UpdatedAt>
            Mis à jour le{' '}
            {new Date(strapiArticle?.updatedAt!).toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}
          </UpdatedAt>
          <MarkdownWrapper>
            {strapiArticle?.introduction?.data?.introduction!}
          </MarkdownWrapper>
          {illustrationImage && (
            <Illustration
              image={illustrationImage}
              alt={
                strapiArticle?.image?.alternativeText ||
                `Illustration pour l'article ${strapiArticle?.title}`
              }
              height={illustrationHeight}
              width={illustrationWidth}
            />
          )}
          <MarkdownWrapper>
            {strapiArticle?.content?.data?.content!}
          </MarkdownWrapper>
          <QuoteButton to='/contact/' large>
            Contactez-moi pour réserver votre prestation !
          </QuoteButton>
        </Card>
      </Container>
    </Layout>
  )
}

export default Article

export const query = graphql`
  query Article($id: String) {
    strapiArticle(id: { eq: $id }) {
      content {
        data {
          content
        }
      }
      title
      shortDescription
      updatedAt
      introduction {
        data {
          introduction
        }
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 500, formats: [AUTO, WEBP])
          }
        }
        alternativeText
      }
      seo {
        description
        title
      }
    }
  }
`
